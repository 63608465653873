.icon-container {
  text-align: center;

  &--icon {
    margin-bottom: 0;
  }

  &--title {
    margin-bottom: 0;
    font-weight: 600;
    line-height: 19px;
    color: #004F57;
  }
}
