.home-subscribe {
  border-radius: 4px;
  background-color: #F7F7F7;
  padding: 16px;
  margin-bottom: 20px;
  min-height: 238px;

  &--icon {
    width: 44px;
    flex-shrink: 0;
    margin-right: 20px;
  }

  &--text {
    color: rgba(0,0,0,0.85);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 0;
  }

  &--form {
    display: flex;
    flex-direction: column;
    padding-top: 4px;
  }

  &--input {
    margin-top: 16px !important;
    background-color: transparent !important;
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    border-radius: 0 !important;

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  &--input-error {
    color: #ff0000;
    font-size: 12px;
  }

  &--button {
    margin-top: 24px;
    width: 100%;
  }
}
