@import "../_common/variables";

.home {
  display: flex;
  flex-direction: column;

  &--text {
    opacity: 0.8;
    color: #000000;
    line-height: 24px;
    margin-bottom: 0;
  }

  &--text-list {
    margin-left: 0;
    padding-left: 20px;
  }

  &--body-container {
    margin-top: 48px !important;

    @media screen and (max-width: $xl) {
      margin-top: 40px !important;
    }
  }

  &--items-block-container {
    @media screen and (max-width: $xl) {
      padding-top: 0 !important;
      padding-bottom: 0;
    }
  }

  &--items-container {
    @media screen and (max-width: $sm) {
      margin-top: 0 !important;
    }
  }

  &--join-buttons {
    @media screen and (max-width: 575px) {
      margin-top: 30px;
      justify-content: center;
    }
  }

  &--join-info {
    @media screen and (max-width: 575px) {
      text-align: center;
      line-height: 28px;
    }
  }

  &--info {
    color: #242424;
    max-width: 940px;
    margin: 0 auto 16px;
    line-height: 18px;
    text-align: center;

    @media screen and (max-width: $sm) {
      font-size: 14px;
    }
  }

  &--link {
    color: inherit;
    text-decoration: underline;
  }

  &--block {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 60px;
    padding-bottom: 40px;
  }

  &--recommended {
    background-color: #f7f7f7;
  }

  &--recommended-container {
    padding-top: 36px;
    text-align: center;
    color: #004f57;

    .color-red {
      color: #ff0000;
    }

    &--heading {
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 24px;
      color: #004f57;

      @media screen and (max-width: 400px) {
        font-size: 32px;
        line-height: 32px;
      }

      @media screen and (max-width: 360px) {
        font-size: 28px;
        line-height: 28px;
      }
    }

    &--subheading {
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;

      @media screen and (max-width: 360px) {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  &--directory {
    background-color: #004f571a;
  }

  &--directory-container {
    padding: 60px 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 36px;
    grid-row-gap: 36px;

    &--content {
      grid-area: 1 / 1 / 2 / 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;

      @media screen and (max-width: 576px) {
        grid-area: 1 / 1 / 2 / 3;
      }
    }

    &--heading {
      margin: 0;
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      color: #004f57;

      @media screen and (max-width: 400px) {
        font-size: 32px;
        line-height: 32px;
      }

      @media screen and (max-width: 360px) {
        font-size: 28px;
        line-height: 28px;
      }
    }

    &--subheading {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &--button {
      width: 144px;
      height: 40px;
    }

    &--image-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      grid-area: 1 / 2 / 2 / 3;
    }

    &--image {
      width: auto;
      max-width: 348px;
      height: 100%;

      @media screen and (max-width: 768px) {
        width: 264px;
        height: auto;
      }

      @media screen and (max-width: 576px) {
        display: none;
      }
    }

    &--list {
      grid-area: 2 / 1 / 3 / 3;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      gap: 24px;

      @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }

      @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
      }
    }

    &--list-item {
      position: relative;

      &--text {
        z-index: 1;
        position: absolute;
        color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &--image {
        width: 100%;
        height: auto;
      }
    }
  }

  &--category-block-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
    gap: 20px;
    min-height: 400px;

    &--block {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background: #fff;
      margin: 0 auto;

      &--title {
        font-weight: 700;
        line-height: 16px;
        margin-top: 13px;
      }

      &:hover {
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.15);
      }
    }

    @media screen and (max-width: $lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      min-height: 575px;
    }

    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      min-height: 775px;
    }

    @media screen and (max-width: $sm) {
      gap: 0 20px;
      min-height: 615px;

      &--block {
        width: 100%;
        height: 100%;

        max-width: 98px;
        max-height: 98px;
      }
    }
  }

  &--category-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  &--content-block {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }

  &--content-block-text {
    margin-bottom: 16px;
    opacity: 0.8;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }

  &--filters {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .drop-down {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }

  &--items {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    @media screen and (max-width: 575px) {
      .h2 {
        margin-bottom: 24px;
      }
    }
  }

  &--items-pagination {
    text-align: center;
  }

  &--info-link {
    color: inherit;
    text-decoration: underline;
  }

  &--info-button {
    margin-top: 20px;
    padding-left: 88px !important;
    padding-right: 88px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  &--blog-block {
    height: 301px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &--blog-image {
    height: 165px;
    width: 100%;
    object-fit: cover;
  }

  &--blog-text-block {
    height: 123px;
    padding: 16px 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &--blog-text {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    line-height: 20px;
  }

  &--blog-link {
    color: #ff0000;
    font-weight: 600;
    line-height: 19px;
  }

  &--join {
    background-color: #fbfbfb;
    padding: 40px 0 60px;

    &--mobile-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 20px 20px;
    }
  }

  &--join-title {
    color: #000000;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;

    .highlight {
      color: #ff0000;
    }
  }

  &--join-button-container {
    display: flex;
    gap: 16px;
    margin-top: 16px;

    @media screen and (max-width: 575px) {
      margin-top: 36px;
      justify-content: center;
    }
  }

  &--join-button {
    font-size: 16px !important;
    font-weight: 600 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;

    &_right {
      span {
        color: #ff0000;
      }
    }
  }

  &--button-to-antiques {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 340px) {
      span {
        font-size: 15px;
      }
    }
  }

  &--knowledge-link {
    color: black;
  }

  &--faq-container {
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
    padding: 0 25px;
  }
}

.home-notification {
  .ant-notification-notice-message { margin-left: 0; }
}
