@import "../_common/variables";

.home-sidebar {
  border-radius: 4px;
  background-color: #f7f7f7;
  margin-bottom: 20px;
  min-width: 175px;
  display: flex;
  flex-direction: column;
  min-height: 32px;

  &--row-item-value {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
  }

  &--header {
    display: flex;

    .sidebar-arrow {
      height: 10px;
      width: 10px;
      border-right: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      position: absolute;
      right: 10px;
      top: 13.5px;
      cursor: pointer;
      transition: transform 0.5s linear;

      &.up {
        transform: rotate(-135deg);
      }

      &.down {
        transform: rotate(45deg);
      }
    }
  }

  &--title-container {
    width: 100%;
    padding: 4px 8px;
    border-radius: 4px 4px 0 0;
    background-color: #ff0000;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    flex-shrink: 0;
    margin-bottom: -5px;
    position: relative;
    z-index: 1000;

    &.cursor-pointer {
      cursor: pointer;
    }

    &--title {
      width: 95%;
      margin-bottom: 0;
      color: #ffffff;

      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  &--links {
    padding: 6px 12px 6px 6px;

    &.overflow-auto {
      max-height: 565px;
      overflow: auto;
    }
  }

  &--row {
    width: 100%;
    min-height: 40px;
    padding: 0 2px;
    cursor: pointer;
    border-bottom: 3px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--link {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      line-height: 24px;

      &__selected {
        .home-sidebar--row-items-count,
        .home-sidebar--row-item {
          color: #ff0000;
        }
      }
    }

    &:hover {
      .home-sidebar--row-items-count,
      .home-sidebar--row-item {
        color: #ff0000;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &.selected .home-sidebar--row--link {
      color: #ff3029;
    }
  }

  &--row-item {
    margin: 0 10px 0 0;
  }

  &--row-items-count {
    color: #3e3e3e;
    font-size: 14px;
    opacity: 0.8;
  }

  &--toggle-button {
    margin: 5px 0 5px 2px;
    color: #ff0000;
    cursor: pointer;
  }
}

@media screen and (max-width: $sm) {
  .cat-page-latest--items {
    max-height: 280px;
  }
}

.selected-sub-category {
  color: #ff0000;
}
